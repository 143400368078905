<template>
    <div class="w-full h-full flex h-screen grid grid-cols-12">
        <div
            class="h-full col-span-12 md:col-span-4 lg:col-span-3 text-base bg-navy-midlight relative"
        >
            <div class="w-full h-full flex h-screen grid grid-cols-12 fixed">
                <div
                    class="col-span-12 md:col-span-4 lg:col-span-3"
                    :style="sideBannerBgStyle"
                >
                    <div class="signupSidebanner text-white max-w-3/4 m-auto">
                        <div class="">
                            <img
                                src="@/assets/images/ultimateq-tenant-logo.png"
                                alt="logo"
                                class="logo"
                            />
                            <div
                                class="text-sm text-center text-base leading-4 mt-4"
                            >
                                <div class="my-2">
                                    Online
                                    <span
                                        class="text-primary"
                                    >patient feedback.</span>
                                </div>
                                <div>Real time. Every time.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="h-full col-span-12 md:col-span-8 lg:col-span-9 relative flex flex-col"
        >
            <div class="flex flex-row items-center h-full w-full">
                <div class="flex flex-col text-center">
                    <CheckedIconSvg class="m-auto text-green mb-4 fill-current w-14 h-auto"/>
                    <h1 class="mb-3 text-3xl text-white">Payment Successful</h1>
                    <div class="py-2 px-4 w-full m-auto">
                        Thank you for choosing UltimateQ. 
                    </div>
                    <div class="py-2 px-4 w-full m-auto">
                        A password link has been sent to your email account. 
                        <br/>Please click on the link in the email to set up your password.
                    </div>
                </div>
            </div>
            <GlobalFooter class="w-full h-auto" />
        </div>
    </div>
</template>

<script>
import GlobalFooter from '@/components/shared/GlobalFooter'
import SignupSideBannerBg from '@/assets/images/signupsidebanner-bg.jpg'
import { ref, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { SignupSessionService } from '@/services/signup-session.service'
import CheckedIconSvg from '@/assets/icons/checked.svg'

const { useGetters, useActions } = createNamespacedHelpers('')
const signupSessionService = new SignupSessionService()

export default {
    name: 'Signup',
    components: { GlobalFooter, CheckedIconSvg },
    setup(_, { root }) {
        const route = root.$route
        const signupSessionId = ref(route?.query?.sid)
        //TODO: move this to a composable
        const signupForm = ref({
            email: '',
            firstName: '',
            lastName: '',
            practiceName: '',
            practiceType: '',
            subscribeToNewsletter: false,
            acceptedTermsAndConditions: false,
            surveyToolsFeatureAvailable: false
        })
        const { setSignUpForm } = useActions(['setSignUpForm'])
        const sideBannerBgStyle = ref({
            backgroundImage: `url('${SignupSideBannerBg}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        })

        watch(signupSessionId, async (newValue) => {
            if (!newValue) return
            const foundSignupSession = await signupSessionService.getSignupSession(newValue)
            if (!foundSignupSession) return
            setSignUpForm({ ...(foundSignupSession?.content?.form || {}) })
            signupForm.value = { ...(foundSignupSession?.content?.form || {}) }
        }, { immediate: true })

        return { sideBannerBgStyle }
    },
}
</script>
